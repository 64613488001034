var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("t-page-header", {
        attrs: {
          title: _vm.$tc("Donor", 2),
          leftSize: "nine",
          rightSize: "seven",
          popup:
            "<div class='content'><p>Aqui você pode visualizar todos os doadores, ativos e inativos.</p></div>"
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                false
                  ? _c(
                      "button",
                      { staticClass: "tui tuim ui basic button" },
                      [
                        _c("t-icon", {
                          staticClass: "tui tuim icon",
                          attrs: { icon: "file" }
                        }),
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("ExportTo", { to: ".xlsx" })) +
                            "\n      "
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "t-page-main",
        [
          _c(
            "t-page-sidebar",
            [
              _c("t-filter", {
                ref: "filterDonors",
                attrs: {
                  applyFilter: _vm.applyFilter,
                  form: _vm.filterForm,
                  filterItems: _vm.filterItems,
                  filterUpdated: _vm.filtersUpdated
                },
                scopedSlots: _vm._u([
                  {
                    key: "fields",
                    fn: function() {
                      return [_c("t-donors-filters")]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "t-page-content",
            [
              _vm.donorsData.length === 0 && !_vm.isLoading
                ? _c("t-no-data", {
                    attrs: {
                      header: "Não encontramos nenhum doador",
                      subheader: "Não há daodores"
                    }
                  })
                : _c(
                    "div",
                    [
                      _c("t-donors-totals", {
                        attrs: {
                          isLoading: _vm.isLoadingTotals,
                          totalDonors: _vm.totalDonors,
                          totalActive: _vm.totalActive,
                          totalInactive: _vm.totalInactive
                        }
                      }),
                      _c("t-donors-table", {
                        attrs: {
                          loading: _vm.isLoading,
                          donors: _vm.donorsData
                        },
                        on: { orderBy: _vm.orderBy }
                      }),
                      _vm.totalPages > 1
                        ? _c("t-pagination", {
                            attrs: {
                              btnClickPrev: this.prevPage,
                              btnClickNext: this.nextPage,
                              totalPages: _vm.totalPages,
                              currentPage: _vm.currentPage,
                              pagesSelected: _vm.pagesSelected,
                              pages: [5, 10, 50, 100],
                              goToPage: _vm.goToPage
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import gql from 'graphql-tag'

const query = gql`
  query customersList(
    $page: Int = 1,
    $limit: Int = 10,
    $status: String,
    $fullName: String,
    $activeSubscriptionsCount: Int,
    $activeSubscriptionsCountOperator: String,
    $activeSubscriptionsAmount: Int,
    $activeSubscriptionsAmountOperator: String,
    $activeSubscriptionsTotalAmount: Int,
    $activeSubscriptionsTotalAmountOperator: String,
    $createdAtBeginDate: ISO8601Date,
    $createdAtEndDate: ISO8601Date,
    $orderBy: String = "createdAt",
    $order: String = "asc") {
    customersList(
      page: $page,
      limit: $limit,
      status: $status,
      fullName: $fullName,
      activeSubscriptionsCount: $activeSubscriptionsCount,
      activeSubscriptionsCountOperator: $activeSubscriptionsCountOperator,
      activeSubscriptionsAmount: $activeSubscriptionsAmount,
      activeSubscriptionsAmountOperator: $activeSubscriptionsAmountOperator,
      activeSubscriptionsTotalAmount: $activeSubscriptionsTotalAmount,
      activeSubscriptionsTotalAmountOperator: $activeSubscriptionsTotalAmountOperator,
      createdAtBeginDate: $createdAtBeginDate,
      createdAtEndDate: $createdAtEndDate,
      orderBy: $orderBy,
      order: $order
    ) {
      collection {
        id
        status
        statusLabel
        fullName
        activeSubscriptionsCount
        createdAt
      }
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
    }
}`

async function getDonors ( params = {} ) {
    const { $api }= Vue.prototype
    let beginDate = ''
    let endDate = ''

    if (params.createdAtBeginDate && params.createdAtEndDate) {
      let { createdAtBeginDate, createdAtEndDate } = params
      createdAtBeginDate = createdAtBeginDate.split("/")
      createdAtEndDate = createdAtEndDate.split("/")
      beginDate = new Date(createdAtBeginDate[2], createdAtBeginDate[1] - 1, createdAtBeginDate[0]).toISOString().split("T")[0]
      endDate =  new Date(createdAtEndDate[2], createdAtEndDate[1] - 1, createdAtEndDate[0]).toISOString().split("T")[0]
      params.createdAtBeginDate = beginDate
      params.createdAtEndDate = endDate
    }

    const result = await $api.query({
        query,
        variables: params
    }).then( res => res )
    .catch( e => e )
    return result;
}

export { getDonors }

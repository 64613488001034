import gql from 'graphql-tag'

const query = gql`
  query totalCustomers {
    totalCustomers {
      totalActive
      totalInactive
    }
}`

async function getTotalDonors () {
    const { $api } = Vue.prototype
    const result = await $api.query({
        query
    }).then( res => res )
    .catch( e => e )
    return result;
}

export { getTotalDonors }

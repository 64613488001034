import { mapActions, mapState } from 'vuex'
import { getDonors, getTotalDonors } from '@/queries/donors/donor.queries'
import { checkDate } from '@/helpers'

export default {
  name: 'TDonorsPage',
  title: ({ title }) => title,

  components: {
    TFilter: () => import('@/components/filter'),
    TStatusBar: () => import('@/components/status/bar'),
    TStatusBarItem: () => import('@/components/status/item.vue'),
    TDonorsTable: () => import('@/components/donors/table.vue'),
    TDonorsTotals: () => import('@/components/donors/totals.vue'),
    TDonorsFilters: () => import('@/components/donors/filters.vue'),
  },

  data () {
    return {
      title: this.$tc('Donor', 2),
      isLoading: false,
      isLoadingTotals: false,
      totalPages: "0",
      currentPage: "0",
      pagesSelected: "0",
      limit: 10
    }
  },

  computed: {
    ...mapState({
      donorsData: ({ donors }) => donors.donorsData,
      metaDataDonorsList: ({ donors }) => donors.metaDataDonorsList,
      totalDonors: ({ donors }) => donors.totalDonors,
      totalActive: ({ donors }) => donors.totalActive,
      totalInactive: ({ donors }) => donors.totalInactive,
      filterForm: ({ donors }) => donors.filterForm,
      filterItems: ({ donors }) => donors.filterItems,
      orderParams: ({ donors }) => donors.orderParams
    }),
  },

  methods: {
    ...mapActions('breadcrumb', [ 'changeBreadcrumbItems' ]),
    ...mapActions('donors', [ 'setDonorsData', 'setMetaDataDonorsList', 'setTotalDonors',
    'setTotalActive', 'setTotalInactive', 'addItem', 'clearFilterForm', 'removeItem', 'setOrderParams' ]),
    ...mapActions('filter', [ 'toggleLoading', 'clearFilterItems' ]),
    bindPlugins () {
      $('.tui.ui.accordion').accordion()
      $('.tui.popup').popup({
        transition: 'fade'
      })
    },

    applyFilter () {
      const { filterBy, equalTo, fieldOne, fieldTwo } = this.filterForm

      if (filterBy && equalTo && fieldOne) {
        if (!this.filterItemAlreadySelected(filterBy)) {

          let allowAddItem = false

          if (filterBy.key === 'activeSince') {
            if (fieldOne
            && !checkDate(fieldOne)
            && fieldTwo
            && !checkDate(fieldTwo)) {
              allowAddItem = true
            }
          } else {
            allowAddItem = true
          }

          if (allowAddItem) {
            this.toggleLoading(true)
            setTimeout(() => {
              this.addItem({ filterBy, equalTo, fieldOne, fieldTwo })
              this.searchDonors()
              this.clearFilterForm()
              $('.tui.ui.dropdown').dropdown('clear')
              this.toggleLoading(false)
            }, 1000)
          }
        }
      }
    },

    filterItemAlreadySelected (filterBy) {
      return this.filterItems.length !== 0 && this.filterItems.find( filter => filter.filterBy.key === filterBy.key )
    },

    searchDonors ( params = {} ) {
      let searchParams = this.mountSearchParams()
      searchParams = {
        ...searchParams,
        ...params
      }
      this.isLoading = true
      getDonors( searchParams ).then( res => {
        const { collection, metadata } = res.data.customersList
        this.setDonorsData(collection)
        this.setMetaDataDonorsList(metadata)
        this.updateMetadataTable()
        this.isLoading = false
      }).catch(e => {
        Vue.$log.error('error', e)
        this.isLoading = false
      })
    },

    prevPage () {
      if (this.currentPage === 1) return
      const page = Number(this.currentPage) - 1
      let params = { page }
      this.searchDonors( params )
    },

    nextPage () {
      if (this.currentPage === this.totalPages) return
      const page = Number(this.currentPage) + 1
      let params = { page }
      this.searchDonors( params )
    },

    goToPage (pageNumber) {
      const page = Number(pageNumber)
      if (Number(page) < 0 || Number(page) > Number(this.totalPages)) return
      let params = { page }
      this.searchDonors( params )
    },

    updateMetadataTable () {
      if ( this.metaDataDonorsList ) {
        this.totalPages = `${this.metaDataDonorsList.totalPages}`
        this.currentPage = `${this.metaDataDonorsList.currentPage}`
        this.pagesSelected = `${this.metaDataDonorsList.limitValue}`
        this.total = this.metaDataDonorsList.totalCount
      }
    },

    getTotalsDonors () {
      this.isLoadingTotals = true
      getTotalDonors().then( res => {
        const { totalCustomers } = res.data
        this.setTotalActive(totalCustomers.totalActive)
        this.setTotalInactive(totalCustomers.totalInactive)
        this.setTotalDonors(totalCustomers.totalActive + totalCustomers.totalInactive)
        this.isLoadingTotals = false
      }).catch(e => {
        Vue.$log.error('error', e)
        this.isLoadingTotals = false
      })
    },

    filtersUpdated (item) {
      this.removeItem(item)
      this.searchDonors()
    },

    mountSearchParams () {
      let params = {}
      if (this.filterItems.length > 0) {
        const status = this.filterItems.find( item => item.filterBy.key === 'status' )
        const name = this.filterItems.find( item => item.filterBy.key === 'name' )
        const activeDonations = this.filterItems.find( item => item.filterBy.key === 'activeDonations' )
        const activeSince = this.filterItems.find( item => item.filterBy.key === 'activeSince' )

        if (status) {
          params.status = status.fieldOne.key
        }

        if (name) {
          params.fullName = name.fieldOne
        }

        if (activeDonations) {
          params.activeSubscriptionsCountOperator = activeDonations.equalTo.key
          params.activeSubscriptionsCount = Number(activeDonations.fieldOne)
        }

        if (activeSince) {
          params.createdAtBeginDate = activeSince.fieldOne
          params.createdAtEndDate = activeSince.fieldTwo
        }
      }

      if (this.limit) {
        params.limit = this.limit
      }

      if (this.orderParams) {
        params.orderBy = this.orderParams.orderBy
        params.order = this.orderParams.order
      }

      return params
    },

    orderBy (orderObject) {
      const { field, order } = orderObject
      this.setOrderParams({ orderBy: field, order })
      this.searchDonors()
    }
  },

  mounted () {
    this.bindPlugins()
    this.clearFilterItems()
    this.changeBreadcrumbItems([
      { to: { name: 'donors' }, title: 'Doadores', active: true }
    ])
    this.searchDonors()
    this.getTotalsDonors()
  }
}

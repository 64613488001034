import { mapActions, mapState } from 'vuex'
import { getDonorDetail } from '@/queries/donors/donor.queries'
import { updateDonor } from '@/queries/donors/donor.mutations'
import { getDonations } from '@/queries/donations/donations.queries'

export default {
  name: 'TDonorSinglePage',
  components: {
    TGroupCards: () => import('@/components/group-cards'),
    TGroupDonationItem: () => import('@/components/group-cards/donation-item.vue'),
    TDonorForm: () => import('@/components/donors/form.vue')
  },
  data () {
    return {
      headerTitle: "Doador",
      donorId: null,
      isLoading: false,
      donations: []
    }
  },
  computed: {
    ...mapState({
      donor: ({ donors }) => donors.donorDetail,
      editForm: ({ donors }) => donors.editForm,
      comercialPhone: ({ donors }) => donors.comercialPhone,
      residentialPhone: ({ donors }) => donors.residentialPhone,
      mobilePhone: ({ donors }) => donors.mobilePhone
    }),
    popup () {
      return `<div class='content'><p>Aqui você pode visualizar os dados do Doador e suas últimas doações.</p></div>`
    }
  },
  methods: {
    ...mapActions('notifications', [ 'toggleNotification' ]),
    ...mapActions('breadcrumb', [ 'changeBreadcrumbItems' ]),
    ...mapActions('donors', [ 'setDonorDetail', 'toggleLoadingDetail', 'setEditForm' ]),
    loadMore (e) {
      e.preventDefault()
      this.isLoading = true
      setTimeout(() => {
        this.donations = [...this.donations, ...Array(4)]
        this.isLoading = false
      }, 1500)
    },
    bindjQueryPlugins () {
      $('.tui.checkbox').checkbox()
      $('.tui.dropdown').dropdown()
      $('.tui.popup').popup({
        transition: 'fade'
      })
    },

    save() {

      const { firstName, lastName, email, genderCode, addresses } = this.donor

      let phones = []
      if (this.comercialPhone) {
        const { phoneType, number } = this.comercialPhone
        phones.push({ phoneType, number })
      }

      if (this.residentialPhone) {
        const { phoneType, number } = this.residentialPhone
        phones.push({ phoneType, number })
      }

      if (this.mobilePhone) {
        const { phoneType, number } = this.mobilePhone
        phones.push({ phoneType, number })
      }

      const customer = {
        firstName,
        lastName,
        email,
        genderCode,
        phones
      }

      const { country, state, city, zipcode, street, number, neighborhood, complement } = addresses[0]

      const address = {
        country,
        state,
        city,
        zipcode,
        street,
        number,
        neighborhood,
        complement
      }

      this.setEditForm()
      updateDonor(this.donor.id, customer, address).then(res => {
        const { errors } = res.data.updateCustomer
        let type = ''
        if (!errors) {
          type = 'success'
        } else {
          type = 'error'
        }
        this.toggleNotification({ active: true, type })
      }).catch(err => {
        this.toggleNotification({ active: true, type: 'error' })
        this.$log.error('error', err)
      })
    }
  },
  mounted () {
    this.toggleNotification()

    const { id } = this.$route.params
    this.headerTitle = `${this.headerTitle} ${ id ? `<small class="identification">#${id}</small>` : ''} `
    this.donorId = id

    this.bindjQueryPlugins()
    this.changeBreadcrumbItems([
      { to: { name: 'donors' }, title: 'Doadores', active: false },
      { to: { name: 'donors.single', params: { id: this.donorId } }, title: 'Doador', active: true }
    ])

    this.setEditForm()
    this.toggleLoadingDetail(true)
    getDonorDetail({ id }).then(res => {
      this.setDonorDetail(res.data.customer)
    }).then(() => {
      const { id } = this.donor
      getDonations({ customerId: id }).then(subs => {
        if (subs.data && subs.data.subscriptions) {
          this.donations = subs.data.subscriptions.collection
        }
        this.toggleLoadingDetail()
      }).catch(e => {
        Vue.$log.error('error', e)
        this.toggleLoadingDetail()
      })
    }).catch(e => {
      Vue.$log.error('error', e)
      this.toggleLoadingDetail()
    })
  }
}

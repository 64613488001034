var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "donors-single" },
    [
      _c("t-page-header", {
        attrs: {
          actionsLeftAligned: true,
          rightSize: "",
          leftSize: "fifteen",
          title: _vm.headerTitle,
          popup: _vm.popup
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "button",
                  {
                    staticClass: "ui icon basic button go-back",
                    on: {
                      click: function($event) {
                        return _vm.$router.go(-1)
                      }
                    }
                  },
                  [
                    _c("t-icon", {
                      staticClass: "tui tuim icon",
                      attrs: { icon: ["far", "long-arrow-left"] }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "innerLeftActions",
            fn: function() {
              return [
                _c("div", { staticClass: "actions" }, [
                  false
                    ? _c(
                        "button",
                        {
                          staticClass: "ui cancel button",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.setEditForm(true)
                            }
                          }
                        },
                        [
                          _vm._v("\n          Editar "),
                          _c("t-icon", {
                            staticClass: "tui tuim icon edit-form",
                            attrs: { icon: ["fa", "pen"] }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editForm
                    ? _c(
                        "button",
                        {
                          staticClass: "ui cancel button",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.setEditForm()
                            }
                          }
                        },
                        [
                          _vm._v("\n          Cancelar\n          "),
                          _c("t-icon", {
                            staticClass: "tui tuim icon",
                            attrs: { icon: ["fa", "times"] }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.editForm
                    ? _c(
                        "button",
                        {
                          staticClass: "ui approve primary button",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.save()
                            }
                          }
                        },
                        [
                          _vm._v("\n          Salvar\n          "),
                          _c("t-icon", {
                            staticClass: "tui tuim icon",
                            attrs: { icon: ["fa", "check"] }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ]
            },
            proxy: true
          },
          {
            key: "notifications",
            fn: function() {
              return [_c("t-notification")]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "t-page-main",
        [
          _c(
            "t-page-sidebar",
            { attrs: { customClass: "full-bg" } },
            [
              _c(
                "t-group-cards",
                {
                  attrs: { title: "Doações", icon: "heart" },
                  scopedSlots: _vm._u(
                    [
                      false
                        ? {
                            key: "footer",
                            fn: function() {
                              return [
                                _c(
                                  "button",
                                  {
                                    class:
                                      "ui fluid " +
                                      (_vm.isLoading ? "loading" : "") +
                                      " button",
                                    on: { click: _vm.loadMore }
                                  },
                                  [
                                    _c("t-icon", {
                                      attrs: { icon: ["fas", "sync-alt"] }
                                    }),
                                    _vm._v(
                                      "\n            Carregar mais\n          "
                                    )
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        : null
                    ],
                    null,
                    true
                  )
                },
                [
                  _vm.donations.length == 0
                    ? _c(
                        "div",
                        { staticClass: "placeholder-donation" },
                        [_c("t-placeholder", { attrs: { lines: 2 } })],
                        1
                      )
                    : _vm._l(_vm.donations, function(item, i) {
                        return _c("t-group-donation-item", {
                          key: i,
                          attrs: { item: item }
                        })
                      })
                ],
                2
              )
            ],
            1
          ),
          _c("t-page-content", [_c("t-donor-form")], 1)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import gql from 'graphql-tag'

const mutation = gql`
  mutation updateCustomer(
    $id: ID!,
    $customer: UpdateCustomerInput!,
    $address: CreateAddressInput
  ) {
    updateCustomer(
      id: $id,
      customer: $customer,
      address: $address
    ) {
      errors {
        fieldName
        message
      }
    }
}`

async function updateDonor ( id, customer, address ) {
  const { $api } = Vue.prototype
  const result = await $api.mutate({
    mutation,
    variables: { id, customer, address }
  }).then( res => res )
  .catch( e => { throw e } )
  return result;
}
export { updateDonor }

import gql from 'graphql-tag'

const query = gql`
  query customer(
    $id: ID!
  ) {
  customer(
    id: $id
  ) {
    id
    identification
    genderCode
    genderName
    addresses {
      id
      street
      number
      complement
      neighborhood
      city
      state
      zipcode
      country
    }
    documentType
    documentTypeLabel
    firstName
    lastName
    fullName
    email
    phones {
      id
      number
      phoneType
      phoneTypeLabel
    }
  }
}`

async function getDonorDetail ( params = {} ) {
    const { $api }= Vue.prototype
    const result = await $api.query({
        query,
        variables: params,
        fetchPolicy: 'no-cache'
    }).then( res => res )
    .catch( e => e )
    return result;
}

export { getDonorDetail }
